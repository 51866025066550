
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/class-video/List.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { ClassActions, ClassGetters } from "@/store/vm/class/enums";

interface ComponentData {
  classIdModal: number | boolean;
}
export default defineComponent({
  name: "class-video",
  props: {
    widgetClasses: String,
  },
  components: {
    List,
  },
  data(): ComponentData {
    return {
      classIdModal: false,
    };
  },
  methods: {
    onUpsert(classId: number | boolean) {
      this.classIdModal = classId;
    },
    onReset() {
      this.classIdModal = false;
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Đào tạo", ["Khóa học video"]);
      MenuComponent.reinitialization();
    });

    const onDel = (classId) => {
      const detail = store.getters[ClassGetters.GET_BY_ID](classId);

      Swal.fire({
        title: "Bạn muốn xóa khóa học video này?",
        text: detail.name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(classId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (classId) => {
      return store.dispatch(ClassActions.DELETE, classId).catch((e) => {
        console.log(e);
      });
    };

    return {
      onDel,
    };
  },
});
